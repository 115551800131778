import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { TemplateProps } from '../utils/types'
import { renderSections } from '../utils/sections'
import Cta from '../components/cta'

export const query = graphql`
  query Page($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      ...PageData
    }
  }
`

const Page: React.FC<TemplateProps> = ({
  data: { page },
  pageContext: {
    navigation,
    footerNavigation,
    helpNavigation,
    ourStoryNavigation,
  },
}) => {
  return (
    <Layout
      page={page}
      navigation={navigation}
      footerNavigation={footerNavigation}
      helpNavigation={helpNavigation}
      ourStoryNavigation={ourStoryNavigation}
    >
      {page?.pageHero && <Cta {...page.pageHero} />}
      {renderSections(page)}
    </Layout>
  )
}

export default Page
